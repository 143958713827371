import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";
import { blogList } from "../../data/blogs";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/checklist-for-selling-your-san-diego-home-quickly"
  );

  return (
    <LayoutWrapper
      title="A Checklist for Selling Your San Diego Home Quickly"
      desc="Trying to sell your house fast in San Diego? Check out our essential checklist to ensure a smooth and accelerated selling process. Read more now!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              A Checklist for Selling Your San Diego Home Quickly
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Nov 11, 2023</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_A Checklist For Selling Your San Diego Home Quickly.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The city’s housing market is thriving as San Diego quickly becomes one of the most
                popular places to live. If you’re looking to{" "}
                <a
                  href="https://selbysellssd.com/sell-your-san-diego-home/"
                  target="_Blank"
                  className="text-blue-500"
                >
                  sell your San Diego property,
                </a>{" "}
                you probably have a lot on your mind trying to prepare.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Securing the right price is one factor for San Diego homeowners selling their
                properties, but the timeline of your sale can be equally as important. This is
                especially true if your property is your primary dwelling and you must negotiate
                moving directly from one home to another.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                At Selby Realty, we can help you sell your property on a timeline that suits your
                needs. Here’s a checklist for selling your San Diego home quickly, boosting its
                value on the market.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Don’t Neglect Your Landscaping
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The state of the landscaping is one of the first things a potential buyer will
                notice when browsing your listing online or arriving at your home for a tour. Nice
                landscaping doesn’t need to cost a fortune, but it should be neat with a
                straightforward design. Even something as simple as well-placed potted plants or
                flower bushes could significantly brighten up your curb appeal.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Repaint the Interior
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you’ve been living in your home for a while, you may no longer notice the places
                where paint has chipped, stained, or simply become a faded version of its original
                color. A fresh coat of paint on your home’s interior could eliminate those spots and
                do wonders for your property’s market value.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Remove Clutter for Photos
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You don’t need to wait until your home is entirely cleaned out to take photos for
                your listing. Packing your listing with pictures of a completely furnished space
                might help your potential buyers to better imagine their lives there.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                However, take out any items that might make your home appear cluttered and stick to
                the more essential furniture pieces. This will give viewers the impression of more
                space and make your home feel bigger.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Hire the Right Agent
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You could spend months preparing your home to sell for a high price to no avail.
                Without the perfect real estate agent for your needs, you may not achieve the result
                you were hoping for. This is why hiring a real estate agent who has your best
                interests in mind is so important.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                San Diego real estate professionals have years of experience in the immediate area
                and countless connections with other professionals in the community, such as
                contractors, stagers, property lawyers, and more. With someone like that on your
                side — someone who truly understands your home’s unique value — you’re sure to get
                everything you wanted out of selling your home.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Contact the Selby Team Today to Sell Your San Diego Home
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Contact the Selby Team Today to Sell Your San Diego Home{" "}
                <a
                  href="https://selbysellssd.com/san-diego-real-estate-agents/"
                  target="_Blank"
                  className="text-blue-500"
                >
                  real estate agent
                </a>{" "}
                for you is waiting at the Selby Team.
                <ContactSlideoutLink text="Contact us" /> to start the selling process or find your
                new dream home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
